@import "components/variables";


fieldset {
  border: none;
  padding: 0;
}


.form-h-info {
  margin-top: 5px;
  font-size: $font10;
  max-height: 0;
  overflow: hidden;
  color: $txt-light;
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}


.form__select {
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;

  label {
    color: $label;
    font-size: $font13;
    margin-bottom: 6px;
    font-weight: 500;
    display: block;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: $font16;
    padding: 8px 80px 8px 8px;
    border: 1px solid $input-border;

    display: block;
    background: transparent;
    width: 100%;
    padding-right: 40px;
    font-size: $font16;

    line-height: 1.5;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      outline: 2px solid $green;
      border-color: $green;
      box-shadow: none;
      outline-offset: 0;
      // outline:none;
    }
  }

  option {
    border: none;
    padding: 10px;
    font-size: $font16;
    color: $input;
  }

  &:after {
    content: "";
    position: absolute;
    right: 20px;
    bottom: 18px;
    z-index: -1;
    width: 10px;
    height: 10px;
    display: block;
    border-left: 2px solid $input;
    border-bottom: 2px solid $input;
    transform: rotate(-45deg);
  }

  @media(max-width: $grid-breakpoints-sm) {
    width: 100%;

    select {
      width: 100%;
    }
  }

}


.form__checkbox {
  margin-bottom: 15px;

  input {
    position: absolute;
    left: -5000px;
    opacity: 0;
  }

  a {
    text-decoration: underline;
    color: $txt;

    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }
  }

  label {
    position: relative;
    padding-left: 30px;
    line-height: 20px;
    color: $txt;
    font-size: $font14;
    line-height: 1.4;
    cursor: pointer;
    display: inline-block;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      top: -2px;
      left: 0;
      display: block;
      border: 1px solid $main;
      background-color: #fff;
      transition: all 0.3s ease-out;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 4px;
      left: 5px;
      width: 0;
      height: 0px;
      transform: rotate(-45deg);
    }
  }

  input:checked ~ label:before {
    background-color: $main;

  }

  input:checked ~ label:after {

    width: 10px;
    height: 6px;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
  }

  input:focus-visible ~ label:before {
    outline: 2px solid #00af9a;
    outline-offset: 2px;
  }

  input[type="radio"] ~ label {

    &:before {
      border-radius: 50%;
    }
  }

  input[type="radio"]:checked ~ label:after {
    width: 12px;
    height: 12px;
    border: none;
    border-radius: 50%;
    background: $main;
    top: 2px;
    left: 4px;

  }

  input[type="radio"]:checked ~ label:before {
    background: #fff;
  }

  &.is--invalid {

    label:before {
      border-color: $red;
    }
  }
}


.form__checkbox--small {
  label {
    font-size: $font10;
  }
}

.label, .label2 {
  font-size: $font13;
  margin-bottom: 5px;
  font-weight: 700;
  color: $main;
  display: block;
  // margin-left:20px;
}


.form__input {
  margin-bottom: 15px;
  position: relative;


  input {

    border: 1px solid $line;
    width: 100%;
    display: block;
    color: $input;
    line-height: 1.3;
  }

  label, .label {
    font-size: $font13;
    margin-bottom: 5px;
    font-weight: 700;
    color: $main;
    display: block;
    min-height: 22px;

    small {
      font-size: 80%;
      font-weight: 400;
    }

    // margin-left:20px;
  }


  input,
  textarea {

    border: 1px solid $main-light;
    width: 100%;
    display: block;
    color: $input;
    font-size: 16px;
    padding: 15px 35px 12px 20px;

    // border-radius: 25px;

    &:focus,
    &:focus-visible {
      outline: 2px solid #d6deec;
      border-color: #d6deec !important;
      box-shadow: none;
      outline-offset: 0;
      // outline:none;
    }
  }


  &.is--invalid {

    input,
    textarea {
      border-color: $red !important;

      &:focus,
      &:focus-visible {
        outline: 2px solid $red;
        border-color: $red;
        box-shadow: none;
      }
    }

    &:after {
      content: "+";
      transform: rotate(45deg);
      color: $red;
      position: absolute;
      background: transparent;
      top: 32px;
      right: 10px;
      font-size: $font20;

    }
  }

  &.is--valid {

    input,
    textarea {
      border-color: $green !important;

      &:focus,
      &:focus-visible {
        outline-color: $green;
      }
    }

    &:after {
      content: "";
      transform: rotate(-45deg);
      border-left: 2px solid $green;
      border-bottom: 2px solid $green;
      position: absolute;
      top: 42px;
      right: 15px;
      display: block;
      width: 12px;
      height: 8px;
    }
  }

}

.form__input--bold {
  label {
    font-size: $font20;
    color: $txt;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &.is--valid:after {
    top: 52px;
  }

  &.is--invalid:after {
    top: 46px;
  }

}

.form__input--error {
  color: $red;
  font-size: $font10;
  display: block;
  margin-top: 5px;

}

.form__checkbox {
  .form__input--error {
    margin-bottom: 5px;
  }
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}

.form-s {
  fieldset {
    margin-bottom: 30px;
  }

  fieldset {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 20px;
  }

  .form__input--25 {
    max-width: calc(50% - 15px)
  }

  .form__input,
  .form__select {
    width: 100%;
  }

  @media(min-width: 768px) {
    .form__input,
    .form__select {
      width: 100%;
    }
  }

  @media(min-width: 992px) {

    .form__input,
    .form__select,
    .form__checkbox--50 {
      flex: 0 0 50%;
      max-width: calc(50% - 10px)
    }

    .form__input--25 {
      max-width: calc(25% - 15px)
    }
  }
}


.cart__customer {
  display: flex;
  margin-bottom: 30px;
  margin-top: 40px;
  align-items: center;
  gap: 15px;
  font-size: $font14;

  .form__checkbox {
    margin: 0;

    label {
      font-size: $font16;
      font-weight: 700;
      border: none;
      color: $txt;
      min-height: auto;

      &:before {
        top: 50% !important;
        transform: translateY(-50%);
      }

      &:after {
        top: 50% !important;
        transform: translateY(-50%);
      }
    }

    // input[type=radio]:checked~label:after {
    //     left: 5x;
    // }
  }

  @media(max-width: 992px) {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}


.button__password {
  position: absolute;
  right: 2px;
  top: 26px;
  width: 40px;
  height: 38px;
  background-image: url("../img/eye.svg");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  z-index: 20;
  background-color: transparent;


  &:after {
    content: "";
    display: block;
    height: 1px;
    background: #ddd;
    transform: rotate(45deg);
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 20px;
    top: 18px;
    left: 10px;
  }


  &.show {
    &:after {
      top: 10px;
      left: 10px;
      width: 0px;
    }
  }

}

.form__password.form__input {
  &:after {
    content: none !important;
    opacity: 0;
  }
}


.btn-gnerate {

  background: #fff;

  font-size: $font14;
  color: $txt;
  padding: 10px 15px;
  white-space: nowrap;
  font-weight: 700;
  border: 1px solid $txt !important;

  @media(hover: hover) {
    &:hover {
      background-color: $main-light;
    }
  }

}

.generate__score {
  transition: all 0.3s ease-out;
  max-height: 0px;
  overflow: hidden;
  display: flex;
  font-size: $font14;
  align-items: center;

  line-height: 1.2;
  gap: 10px;

  @media (min-width: $grid-breakpoints-xl) {
    gap: 20px;
  }


  @media (max-width: 410px) {
    font-size: $font12;
  }

  .generate__password {
    display: block;
  }

  &.open {
    max-height: 100px;
  }
}


#input-accept {
  border: none;
  background: none;
  color: $main;
  padding: 10px 0;
  font-size: $font14;
  font-weight: 700;
}

.generate__wrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
  color: $txt;

  @media (min-width: $grid-breakpoints-lg) {
    margin-top: 25px;

  }

  @media (min-width: $grid-breakpoints-lg) {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
    align-items: flex-start;
  }


}

@media (max-width: 390px) {
  .generate__wrapper {
    flex-direction: column;
  }

  .generate__score .generate__password {
    display: inline-block;
    margin-left: 10px;
  }
}


.fieldset-outer {
  border-bottom: 4px solid #b3e4fa;
  padding-bottom: 30px;
  margin-bottom: 30px;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;

  [class*=col-] {
    margin-bottom: 0px;
  }
}

.fieldset--start {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}


.fieldset1 {

  legend {
    font-size: 30px;
    color: $main;

    font-weight: 600;

    margin-bottom: 30px;
  }
  @media(max-width: $grid-breakpoints-md){
    legend {
      font-size: 26px;
    }
  }
}

.formRadio {
  label {
    width: 100%;
    height: 180px;
    color: #08abed;
    font-size: 30px;
    font-weight: 600;
    font-style: italic;
    border: 1px solid #b3e4fa;

    line-height: 1.3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    text-align: center;
    cursor: pointer;

    @media(max-width: $grid-breakpoints-md){
      height: 120px;
       font-size: 26px;
    }

  }

  input {
    opacity: 0;
    position: absolute;
    z-index: -10000;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  input:checked + label {
    background: #08abed;
    color: #fff;
  }
}

.formRadio--2 {
  label {
    border-color: #c7c1db;
    color: #4a3887;

  }

  input:checked + label {
    background: #4a3887;
  }
}

.form__checkbox--2 {

  label {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;

    &:before {
      content: none !important;
    }

    &:after {
      content: none !important;
    }

    padding: 10px 15px;
    border: 1px solid $main-light;
    color: $main;
  }

  margin-bottom: 10px;

  input:checked + label {
    background: $main;
    color: #fff;
  }

}


.group-describe {
  border: 1px solid $main-light;
  padding: 25px 20px 10px 20px;
  position: relative;


  .label {
    margin-bottom: 20px;
    padding: 5px 10px;
    position: absolute;
    top: -13px;
    left: 10px;
    background: #fff;
  }
}

.group-describe-outer {
  padding-top: 20px;
  padding-bottom: 30px;
}


@media(min-width: $grid-breakpoints-md) {
  .group-describe-md-50 {
    max-width: 50%;
  }
}


.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-add {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid $main;
  background: #fff;
  color: $main;
  font-weight: 700;
  line-height: 22px;

  &:before {
    content: "+";
    margin-right: 12px;
    font-size: 20px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: $main;
    color: #fff;
    border-radius: 50%;
  }
}

.medicione-border {
  padding: 20px;
  border: 1px solid #d6deec;
  position: relative;
  margin-top: 40px;
  padding-top: 40px;
}

.register__h {
  position: absolute;
  top: -15px;
  background: $main;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;

}


.medicine-title {
  color: $main;
  padding: 10px 20px;
  text-align: center;
  background: #d6deec;
  font-weight: 600;
}


.btn--big {
  color: #fff;
  background: $main;
  padding: 20px 40px;
  font-size: 24px;
  border: none;
  font-weight:700
}

#objawy_niepozadane {
  min-height: 240px;
}


.removeMember {
  color: $red;
  line-height: 18px;
  position: absolute;
  right: 20px;
  top: 10px;
  background: transparent;
  border: none;
  display: inline-flex;
  gap: 5px;
  font-size: 12px;
  opacity: 0.3;

  &:after {
    content: "+";
    font-size: 16px;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: $red;
    color: #fff;
    border-radius: 50%;
    transform: rotate(45deg);
  }

  &:hover {
    opacity: 1;
  }

}


.medicineToCopyWrapper--1 {
  .medicine-title {
    background: #b3e4fa;
  }

  .register__h {
    background: #b3e4fa;
    color: $main;
  }

  .medicione-border {
    border-color: #b3e4fa;
  }

  .btn-add {
    background: #b3e4fa;
    border-color: #b3e4fa;
  }
}

.medicineToCopyWrapper2 {
  .medicine-title {
    background: #c7c1db;
  }

  .register__h {
    background: #c7c1db;
    color: $main;
  }

  .medicione-border {
    border-color: #c7c1db;
  }

  .btn-add {
    background: #c7c1db;
    border-color: #c7c1db;
  }
}


.error {
  color: $red;
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
  display:none;

  &:before {
    content: "! ";
  }
}


.pacjent {
  .fieldset-outer {
    border-bottom-color: #4a3887;
  }

  .btn--big {
    background: #4a3887;
  }

  .legend1 {
    color: #4a3887;
  }
  .group-adress {
    .error {
      display: none !important;
      max-height: 0 !important;
      overflow: hidden;
    }
    .star{
      display:none;
    }
  }
}