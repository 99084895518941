.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 10px;

  ul {
    display: flex;
    flex-wrap: wrap;

    span,
    a {
      font-size: 14px;
      color: $txt;
      display: inline-block;

      padding: 5px 0;
      line-height: 1;
      font-weight: 300;

      &:before {
        margin-right: 10px;
        color: $txt;
        content: "/";

      }
    }

    li:first-child {
      a:before {
        content: none;
      }
    }

    a {
      margin-right: 10px;


      svg {
        stroke: $txt;
        transition: all 0.3s ease-in;
      }


      &:focus,
      &:hover {
        outline: none;
        color: $main;

        svg {
          stroke: $main;

        }

        &:after {
          color: $txt;
        }

      }
    }
  }

  @media(min-width: $grid-breakpoints-md) {
    ul {

      a,
      span {
        font-size: 16px;
      }
    }
  }


}

@media(min-width: $grid-breakpoints-xl) {
  .page__header .breadcrumbs ul {

    a,
    span {
      font-size: 18px;
    }
  }
}

@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

.menu-is-fixed {
  //padding-top: 150px;
}


.header {

  //background: $main;
  position: relative;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;

  &.fixed-header-scroll {
    position: fixed;
    top: 0;

    background: $main;
    -webkit-animation-name: fixedMenuDown;
    animation-name: fixedMenuDown;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);

    .header__top {
      display: none;
    }

    .header__logo img {
      filter: brightness(0) invert(1);
    }

    .header__wrapper {
      height: 60px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .header__bottom:after {
      content: none;
    }

    .nav > ul > li > a {
      color: #fff;
    }

    .header__logo {
      bottom: 10px;

      img {
        width: 100px;
      }
    }


  }


}

.header__top {
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.lang__list {
  display: flex;
  margin-right: 70px;

  a {
    display: block;
    padding: 0 10px;
    font-size: $font16;
    font-weight: 300;
    line-height: 1;
    color: #fff;

    &.active {
      font-weight: 700;
    }

    @media(hover: hover) {
      &:hover {
        color: #08ABED;
      }
    }
  }

  li:not(:last-child) {
    border-right: 1px solid #fff;
  }

}


.header__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 220px;
  position: relative;
  height: 170px;
  padding-top: 20px;
  padding-bottom: 20px;

}


.header__logo {
  position: absolute;
  bottom: 0%;
  left: 20px;
  z-index: 10;
  transition: none;

  img {
    width: 240px;
  }
}

.headerForm {
  width: 48px;
  height: 48px;
  position: relative;
  margin-left: 40px;
  margin-right: auto;


  input {
    border: none;
    width: 100%;
    padding-right: 40px;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #fff;
    border-radius: 25px;
    font-weight: 600;
    min-height: 50px;
    border: 2px solid $line;

  }

  ::placeholder {
    color: $txt;
    font-weight: 600;
  }

  button {
    position: absolute;
    width: 48px;
    right: 0px;
    bottom: 0px;
    border: none;
    padding: 0;
    display: inline-block;
    background: transparent;
    height: 48px;
    background-image: url("../img/ico-search-main.svg");
    background-repeat: no-repeat;
    background-position: center;

  }
}


.header__accesibility {
  border: 1px solid $line;
  border-radius: 25px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-right: 30px;
  overflow: hidden;
  margin-left: auto;

  li {
    height: 48px;
  }

  a,
  button {
    display: inline-block;
    height: 100%;
    min-width: 48px;
    height: 48px;
    padding: 0;
    background-color: transparent;
  }
}


.link--add {
  background-image: url("../img/ico-add.svg");
  background-repeat: no-repeat;
  background-position: center;

}

.btn--contrast {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/ico-contrast.svg");
  border: none;
  vertical-align: middle;

  span {
    min-width: 210px;
  }

}


.nav-toggle {
  width: 50px;
  padding: 0;
  padding-left: 50px;
  height: 50px;
  background: none;
  border: none;
  display: none;
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: $font20;
  left: -10px;
  line-height: 50px;

  strong {
    margin-left: -20px;
  }


  span {
    width: 26px;
    height: 2px;
    background: #fff;
    display: block;
    position: absolute;
    left: 12px;
    top: 15px;
    transition: all 0.3s ease-in-out;
    transform-origin: center;

    &:nth-child(2) {
      top: 25px;
    }

    &:nth-child(3) {
      top: 35px;
    }

  }

  &.open span {
    &:first-child {
      transform: rotate(45deg);
      top: 24px;
    }

    &:nth-child(2) {
      left: 50px;
      opacity: 0;
    }

    &:nth-child(3) {
      top: 24px;
      transform: rotate(-45deg);
    }
  }

}


.header__wrapper {
  //background: $main;
}

.header__bottom {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}

.nav > ul {
  display: flex;
  margin-left: -15px;

  > li.active > a {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 4px;
    text-decoration-color: $main2
  }

  > li > a {
    font-weight: 700;
    font-size: $font18;
    color: #fff;
    display: inline-block;
    padding: 10px 15px 10px 20px;

    @media(hover: hover) {
      &:hover {
        color: #08ABED !important;
      }
    }
  }

  a {
    transition-property: color;
  }

  > li:last-child {
    // display: none;

    // >a {
    //     color: $yellow;
    //     position: relative;
    //     padding-left: 45px;

    //     &:before {
    //         content: url("../img/ico-shop.svg");
    //         margin-right: 5px;
    //         position: absolute;
    //         top: 9px;
    //         left: 12px;
    //     }
    // }
  }
}


.header__social {
  display: flex;
  align-items: center;
  margin-top: -5px;

  li {
    height: 48px;
  }
}

.link--social {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-color: trnasparent;
}

.link--fb {
  background-image: url("../img/fb.svg");
}

.link--insta {
  background-image: url("../img/insta.svg");
}

.page__header {
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  overflow: hidden;
  background: $main;

  @media(max-width: $grid-breakpoints-sm) {
    min-height: 200px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.15;
  }

  .breadcrumbs {
    border: none;
  }

  .container {
    position: relative;
    z-index: 1;
    text-align: center;

  }

  h1,
  h2 {
    font-size: $font40;
    font-weight: 900;
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;

    @media(max-width: $grid-breakpoints-sm) {
      font-size: $font34;
    }

  }

  .breadcrumbs__list {
    justify-content: center;
  }

  .breadcrumb__items {

    span {
      color: #fff;

      &:before {
        color: #fff;
      }
    }

    a {
      color: #fff;

      &:before {
        color: #fff;
      }

      @media(hover: hover) {
        &:hover {
          color: $yellow;
        }
      }
    }


  }
}


header > ul > li {
  position: relative;
}


.menu__item--has-children {
  ul {
    display: none;
  }
}

@media(min-width: $grid-breakpoints-sm) {
  .header__wrapper {

    &:after {
      background: $main;
      content: "";
      height: 90px;
      width: 100%;
      left: 220px;
      bottom: 0;
      z-index: -2;
      position: absolute;

    }
  }
}

@media(min-width: $grid-breakpoints-lg) {
  .header__wrapper {

    &:after {
      left: 340px;
    }
  }
}

@media(min-width: $grid-breakpoints-sm) and(max-width: $grid-breakpoints-lg) {
  .header__bottom {
    margin-right: 100px;
  }
}

@media(min-width: $grid-breakpoints-xxl) and(max-width: 1800px) {
  .header__wrapper {
    padding-right: 150px;
  }
}

@media(min-width: 1350px) and(max-width: $grid-breakpoints-xxl) {
  .header__wrapper {
    padding-right: 50px;
  }
}

@media(min-width: $grid-breakpoints-xl) and(max-width: 1350px) {
  .header__wrapper {
    padding-right: 120px;
  }
}

@media(min-width: $grid-breakpoints-lg) and(max-width: $grid-breakpoints-xl) {
  .header__wrapper {
    padding-right: 80px !important;
  }
}

@media(min-width: $grid-breakpoints-xl) {


  .nav > ul > li > a {
    color: #fff;
  }

  .menu__item--has-children {
    position: relative;

    ul {
      padding-top: 16px;
      position: absolute;
      display: block;
      left: -10px;
      height: 0;
      overflow: hidden;
      min-width: 250px;
      z-index: -130;
      top: 100%;
      transition: opacity 0.5s ease-in-out, left 0.3s ease-in-out;
      opacity: 0;


      li {
        background: $main2;

        border-top: none;

        &:first-child a {
          padding-top: 20px;
        }

        &:last-child a {
          padding-bottom: 20px;
        }
      }

      a {
        line-height: 1.2;
        font-size: $font18;
        font-weight: 700;
        display: block;
        padding: 10px 15px;
        color: $main;

        @media(hover: hover) {
          &:hover {
            background: $main2-hover
          }
        }

        &:focus-visible {
          outline-color: $main;
          outline-offset: -2px;
        }

      }

    }

    &.open ul {
      z-index: 30;
      height: initial;
      opacity: 1;
      left: 0;
    }
  }

  .menu__item--has-children:hover {
    ul {
      z-index: 30;
      height: initial;
      opacity: 1;
      left: 0;
    }
  }


}

// .menu__item--has-children>a:focus+ul, .menu__item--has-children>a:focus-visible+ul {

//     z-index: 30;
//     height: initial;
//     opacity: 1;
//     left: 0;

// }


@media(max-width: $grid-breakpoints-xl) {


  .menu-open .header {
    background: $main;
    transition: none;
  }

  .header {
    transition: background 0s ease-out 0.3s;
  }


  .btn__login {
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    padding: 0;
    text-indent: -1000px;
    overflow: hidden;
    height: 48px;
    background: transparent url("../img/user.svg") center no-repeat;
    background-size: 15px;
  }

  .headerForm button {
    background-image: url("../img/ico-search.svg");
  }


  .menu__item--has-children {
    position: relative;

    > a {
      width: 70% !important;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      position: absolute;
      right: 35px;
      top: 20px;
      transform: rotate(-45deg);

    }
  }


  .navMain__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 20px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px solid $txt;


  }


  .header__wrapper {
    flex-wrap: nowrap;
    height: 70px;
    padding-left: 20px;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    width: 100%;
    padding-right: 5px;
  }

  .headerForm {
    width: 100%;
  }

  .navMain {

    position: fixed;
    z-index: 100;
    height: calc(100vh - 60px);
    bottom: 60px;
    width: 80vw;
    left: -80vw;
    transition: left 0.3s ease-out;
    box-shadow: 0px 0px 15px 0 rgb(0, 0, 0, 0.25);
    background: #fff;

    &.open {
      left: 0;
    }

    ul {
      display: block;

      li {
        width: 100%;

        a {
          width: 100%;
          font-size: 16px;
          padding: 10px 0;
          display: inline-block;
          color: $txt;

        }
      }

      ul {

        width: 100%;
        position: relative;
        display: none;
        overflow: auto;
        padding: 0;
        transition: all 0.3s ease-out;

        &.open {
          max-height: 500px;
        }

        a {
          padding-left: 20px;
        }

        ul a {
          padding: 7px 0 7px 30px;
          font-size: 14px;

        }
      }
    }

    > ul {
      // max-height: calc(100% - 80px);
      // overflow: auto;

      > li {

        border-bottom: 1px solid $line;

        > a,
        > ul {
          padding-left: 20px;
          padding-left: 20px;
          border: none;
        }
      }
    }
  }

}


@media(max-width: $grid-breakpoints-lg) {
  .header__wrapper.container {
    max-width: 100%;
  }

}

@media(max-width: $grid-breakpoints-sm) {

  .lang__list {
    margin-right: 5px;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    text-align: center;

    li {
      border: none !important;

      a {
        font-size: 10px;
      }
    }
  }
}


.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}


.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}

.menu-open {
  .menu-right__wrapper {
    z-index: 0;
  }
}


.nav-subpage {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $main-light2;

  ul {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
  }

  a {
    color: $main;
    position: relative;
    padding: 5px 0;
    font-size: $font18;
    font-weight: 700;
    display: block;

    &:before {
      content: "•  ";

    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 4px;
      left: 0;
      width: 0;
      transition: all 0.3s ease-in-out;
      background: $main;
    }

    &.active {
      color: $main2;

      &:after {
        background: $main2;
        width: 100%;
      }
    }

    @media(hover: hover) {
      &:hover {
        color: $main2;

        &:after {
          width: 100%;
        }
      }
    }
  }

}

@media(max-width: $grid-breakpoints-xl) {


  .nav .nav__wrapper {
    // display: none;
    position: absolute;
    position: absolute;
    top: 100%;
    flex-direction: column;
    background: $main;
    width: 100%;
    left: 20px;
    padding: 0 0px 0 10px;
    max-height: 0;
    overflow: hidden;
    margin-top: 0;
    transition: all 0.3s ease-out;

    &.open {
      max-height: calc(100vh - 190px);
      overflow: auto;
    }

    > li:first-child {
      margin-top: 15px;
    }

    > li:last-child {
      margin-bottom: 25px;
    }
  }


  .nav {
    position: relative;
    width: 100%;

    > ul > li > a {
      padding-top: 10px;
      width: 100%;
      text-align: center;
    }
  }


  .nav-toggle {
    display: block;

  }

  .header__social {
    position: absolute;
    bottom: 00px;
    right: 20px;
  }

  .header__bottom {
    position: relative;
  }

  .menu__item--has-children ul {
    padding-left: 20px;
    display: block;

    a {
      color: #fff;
      display: block;
      padding: 10px;

    }
  }
}


@media(max-width: $grid-breakpoints-xl) {
  .header__top.container, .header__bottom.container {
    max-width: 100%;
  }

  .nav__wrapper.nav__level-1 ul {
    display: none;

  }

  .nav__wrapper.nav__level-1 ul.open {
    max-height: 600px;
    overflow: auto;
  }


  .nav .nav__wrapper {
    left: -20px;
    width: 100vw;
    position: fixed;
    top: 70px;
    left: 0;
    margin-left: 0;
  }

  .fixed-header-scroll .nav .nav__wrapper {
    top: 60px;
  }

  .nav > ul > li > a {
    font-size: $font26;

  }


  .menu__item--has-children ul a {
    font-size: $font26;
  }

  .header__bottom {
    margin-top: 0;
    width: 110px;
    flex: 0 0 110px;
    height: 48px;
    justify-content: flex-end;

  }

  .header__top {
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    margin-left: auto;

    .btn--help {
      position: absolute;
      top: 68px;
      right: 20px;
      padding-right: 15px !important;
      padding-left: 40px;
      padding-top: 0;
      padding-bottom: 0;

      height: 48px;
      font-size: 16px;
      line-height: 1;
      z-index: 80;
      line-height: 48px;


      &:after {
        left: 15px;
        margin-top: 3px;
        text-indent: 0;
      }
    }
  }


  .link--social {
    display: none;
  }


  .header__social {
    margin-right: 150px;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    margin-top: 0;
    bottom: -4px;

    li {
      height: 60px;
    }
  }

  .header__logo {
    max-width: calc(100% - 220px);
    height: auto;
    position: static;
    transform: none;
    line-height: 1;
    flex: 0 0 160px;

    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .header__accesibility {
    margin-right: 0;
    gap: 2px;
  }

  .nav-toggle {

  }

  .nav {
    margin-left: auto;
    flex: 0 0 50px;
    max-width: 50px;
  }

  .headerForm {
    position: absolute;
    bottom: 0px;
    height: 50px;
    width: 50px;
    left: 0px;
    margin: 0;

    input {
      padding-top: 2px;
      padding-bottom: 2px;
      min-height: auto;
    }
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .nav .nav__wrapper.open {
    max-height: calc(100vh - 70px);
    height: 100vh;
    overflow: auto;
  }
  .nav > ul > li > a {
    text-align: left;


  }

  .header {
    background: $main;

    .header__logo img {
      filter: brightness(0) invert(1);
    }
  }

}


.menu-right__wrapper {

  position: absolute;
  padding-top: 49%;
  right: 0;
  z-index: 200;
  width: 12%;
  overflow: hidden;
  top: 0;
  min-height: 500px;

  max-height: 75vh;

  @media(min-width: $grid-breakpoints-md) {
    figure {
      position: absolute;
      height: 100%;
      top:0; width:100%;
      overflow: hidden;


    }
  }

  .menu-right__bg {
    height: 100%;;
    width: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .menu-right {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
  }
}

.mainpage{
  .menu-right__wrapper{
    @media(min-width: $grid-breakpoints-lg) {

        min-height: 560px;

    }
    @media(min-width: $grid-breakpoints-lg) {

        min-height: 660px;

    }
    @media(min-width: $grid-breakpoints-xxl) {
      figure {
        min-height: 800px;
      }
    }
  }
}




.menu-right__btn {
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
  display: inline-block;
  background: $main url("../img/ico-mail.svg") no-repeat center;
  background-size: 60%;

}


.menu-right__btn--share {
  background-color: #B3E4FA;
  background-image: url("../img/ico-share.svg");
}

@media(max-width: $grid-breakpoints-xxl) {

  .menu-right__btn {
    width: 48px;
    height: 48px;
    margin-bottom: 5px;
  }

  .logo-ue img {
    width: 48px;
    height: auto;
  }
}


@media(max-width: $grid-breakpoints-sm) {
  .menu-right__wrapper {
    top: 70px;
    padding-top: 55%;

    .menu-right__bg {
      display: none;
    }
  }

}