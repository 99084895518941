@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  vertical-align: middle;
}

button {
  padding: 0;
  color: #000;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
  color: #000;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-center-10 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.relative {
  position: relative !important;
}

.container {
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1100px;
}
@media (max-width: 576px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .container--md {
    max-width: 1000px;
  }
}
.container--500 {
  max-width: 490px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}

[class*=col-] {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  [class*=col-] {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
  }
}

.row--16 {
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);
}
.row--16 [class*=col-] {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  margin-bottom: 16px;
}

@media (min-width: 1200px) {
  .row--60 {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
  }
  .row--60 [class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    margin-bottom: 60px;
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

@media (min-width: 576px) {
  .col-sm-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 768px) {
  .col-md-1 {
    max-width: 8.33%;
    flex: 0 0 8.33%;
  }

  .col-md-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-md-5 {
    width: 41.66%;
    flex: 41.66%;
  }

  .col-md-50,
.col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.33%;
    flex: 0 0 58.33%;
  }

  .col-md-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-11 {
    max-width: 91.66%;
    flex: 0 0 91.66%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 992px) {
  .col-lg-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-8 {
    max-width: 66.6%;
    flex: 0 0 66.6%;
  }
}
@media (min-width: 1200px) {
  .col-xl-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xl-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-xl-20 {
    max-width: 20%;
    flex: 0 0 20%;
  }
}
.section {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 576px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section--30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ml-auto {
  margin-left: auto !important;
}

.p-20 {
  padding: 20px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 768px) {
  .mt-100 {
    margin-top: 50px !important;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}
@media (max-width: 768px) {
  .mb-100 {
    margin-bottom: 60px !important;
  }
}

/* lato-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/lato-v24-latin_latin-ext-300.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato-v24-latin_latin-ext-300.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v24-latin_latin-ext-300.woff2") format("woff2"), url("../fonts/lato-v24-latin_latin-ext-300.woff") format("woff"), url("../fonts/lato-v24-latin_latin-ext-300.ttf") format("truetype"), url("../fonts/lato-v24-latin_latin-ext-300.svg#Lato") format("svg");
  /* Legacy iOS */
}
/* lato-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/lato-v24-latin_latin-ext-300italic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato-v24-latin_latin-ext-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v24-latin_latin-ext-300italic.woff2") format("woff2"), url("../fonts/lato-v24-latin_latin-ext-300italic.woff") format("woff"), url("../fonts/lato-v24-latin_latin-ext-300italic.ttf") format("truetype"), url("../fonts/lato-v24-latin_latin-ext-300italic.svg#Lato") format("svg");
  /* Legacy iOS */
}
/* lato-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lato-v24-latin_latin-ext-regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato-v24-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v24-latin_latin-ext-regular.woff2") format("woff2"), url("../fonts/lato-v24-latin_latin-ext-regular.woff") format("woff"), url("../fonts/lato-v24-latin_latin-ext-regular.ttf") format("truetype"), url("../fonts/lato-v24-latin_latin-ext-regular.svg#Lato") format("svg");
  /* Legacy iOS */
}
/* lato-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/lato-v24-latin_latin-ext-italic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato-v24-latin_latin-ext-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v24-latin_latin-ext-italic.woff2") format("woff2"), url("../fonts/lato-v24-latin_latin-ext-italic.woff") format("woff"), url("../fonts/lato-v24-latin_latin-ext-italic.ttf") format("truetype"), url("../fonts/lato-v24-latin_latin-ext-italic.svg#Lato") format("svg");
  /* Legacy iOS */
}
/* lato-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/lato-v24-latin_latin-ext-700.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato-v24-latin_latin-ext-700.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v24-latin_latin-ext-700.woff2") format("woff2"), url("../fonts/lato-v24-latin_latin-ext-700.woff") format("woff"), url("../fonts/lato-v24-latin_latin-ext-700.ttf") format("truetype"), url("../fonts/lato-v24-latin_latin-ext-700.svg#Lato") format("svg");
  /* Legacy iOS */
}
/* lato-700italic - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/lato-v24-latin_latin-ext-700italic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato-v24-latin_latin-ext-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v24-latin_latin-ext-700italic.woff2") format("woff2"), url("../fonts/lato-v24-latin_latin-ext-700italic.woff") format("woff"), url("../fonts/lato-v24-latin_latin-ext-700italic.ttf") format("truetype"), url("../fonts/lato-v24-latin_latin-ext-700italic.svg#Lato") format("svg");
  /* Legacy iOS */
}
/* lato-900 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/lato-v24-latin_latin-ext-900.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato-v24-latin_latin-ext-900.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v24-latin_latin-ext-900.woff2") format("woff2"), url("../fonts/lato-v24-latin_latin-ext-900.woff") format("woff"), url("../fonts/lato-v24-latin_latin-ext-900.ttf") format("truetype"), url("../fonts/lato-v24-latin_latin-ext-900.svg#Lato") format("svg");
  /* Legacy iOS */
}
/* lato-900italic - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/lato-v24-latin_latin-ext-900italic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato-v24-latin_latin-ext-900italic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v24-latin_latin-ext-900italic.woff2") format("woff2"), url("../fonts/lato-v24-latin_latin-ext-900italic.woff") format("woff"), url("../fonts/lato-v24-latin_latin-ext-900italic.ttf") format("truetype"), url("../fonts/lato-v24-latin_latin-ext-900italic.svg#Lato") format("svg");
  /* Legacy iOS */
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 360px) {
  html {
    font-size: 12px;
  }
}

body {
  line-height: 1.4;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #000;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip span {
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  background: #fff;
  z-index: -1000;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 5px;
  font-size: 0.75rem;
  color: #000;
  width: 0;
  height: 0;
  overflow: hidden;
  text-align: center;
  line-height: 1.2;
}
.tooltip:hover span {
  opacity: 1;
  z-index: 1;
  height: auto;
  width: auto;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.lh--2 {
  line-height: 2 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 600 !important;
}

.font--9 {
  font-size: 9px !important;
}

.font--10 {
  font-size: 0.625rem !important;
}

.font--12 {
  font-size: 0.75rem !important;
}

.font--14 {
  font-size: 0.875rem !important;
}

.font--18 {
  font-size: 1.125rem !important;
}

.font--20 {
  font-size: 1.3125rem !important;
}

.main {
  color: #1F0970 !important;
}

.white {
  color: #fff !important;
}

.black {
  color: #000 !important;
}

.main2 {
  color: #1F0970 !important;
}

.txt--center,
.text-center {
  text-align: center !important;
}

.txt--left {
  text-align: left !important;
}

.txt--right {
  text-align: right !important;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid #1F0970;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a {
  color: #1F0970;
  text-decoration: none;
}
@media (hover: hover) {
  a:hover {
    color: #08ABED;
  }
}
a:focus-visible {
  outline-offset: 2px;
}

h1,
h2,
h3 {
  letter-spacing: initial;
  line-height: 1.3;
  font-weight: 900;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

h3 {
  font-size: 1.125rem;
  margin-bottom: 20px;
}

.title {
  font-size: 2.5rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-align: center;
  line-height: 1.2;
  color: #1F0970;
  margin-bottom: 30px;
}

.description {
  text-align: center;
  font-size: 1.3125rem;
  margin-bottom: 50px;
}

h4,
h5,
h6 {
  line-height: 1.3;
  font-size: 1.3125rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.list--own ul {
  padding-left: 20px;
  list-style: none;
}
.list--own ul li {
  position: relative;
  margin-bottom: 5px;
}
.list--own ul li:before {
  content: ">";
  position: absolute;
  top: 0px;
  left: -20px;
  font-weight: 700;
}
.list--own ul li ul {
  padding-left: 15px;
}
.list--own ul li li:before {
  top: -8px;
  left: -13px;
  font-size: 20px;
  content: ".";
  font-weight: 700;
}

@media (max-width: 576px) {
  .description {
    font-size: 1.125rem;
  }

  h1,
.title {
    font-size: 1.875rem;
  }

  h2 {
    font-size: 1.625rem;
  }

  h4,
h5,
h6 {
    font-size: 1rem;
  }

  .description {
    margin-bottom: 30px;
  }
}
@media (max-width: 400px) {
  h1,
.title {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}
ul,
ol {
  list-style: none;
}

.page__txt {
  line-height: 1.6;
}
.page__txt img {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  display: inline-block;
  height: auto;
}
.page__txt ul,
.page__txt ol {
  padding-left: 30px;
}
.page__txt ul {
  list-style: initial;
}
.page__txt ol {
  list-style: decimal;
}
.page__txt ol,
.page__txt ul,
.page__txt p {
  margin-bottom: 15px;
}
.page__txt li {
  margin-bottom: 5px;
}
.page__txt a {
  text-decoration: underline;
}
.page__txt h2:not(:first-child) {
  margin-top: 35px;
}
.page__txt h3:not(:first-child) {
  margin-top: 25px;
}

input:not([type=date], [type=number], [type=radio], [type=checkbox]),
textarea,
button {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: "Lato", sans-serif;
}

button {
  color: #000;
}

input,
textarea,
select {
  border: 1px solid #E8E9E5;
  line-height: 1.6;
  padding: 10px 15px;
  color: #000;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  font-family: "Lato", sans-serif;
}

::placeholder {
  color: #6C6C6C;
  font-weight: 400;
  font-style: inherit;
  font-family: "Lato", sans-serif;
}

.disabled {
  color: #6C6C6C;
}

hr {
  border: none;
  border-top: 1px solid #C7C1DB;
  margin-top: 20px;
  margin-bottom: 20px;
}

table {
  max-width: 100%;
  font-size: 0.875rem;
  border-collapse: separate;
  line-height: 1.4;
  font-weight: 300;
}
table * {
  line-height: 1.4;
}
table th {
  background: transparent;
  font-weight: 700;
  border-bottom: 1px solid #C7C1DB;
  padding: 10px 10px;
  vertical-align: middle;
}
table td {
  border-bottom: 1px solid #C7C1DB;
  padding: 10px 10px;
  vertical-align: middle;
}
@media (min-width: 1400px) {
  table {
    font-size: 1rem;
  }
}
table thead {
  position: relative;
}
table thead th {
  border: none;
  padding: 12px 10px 12px 10px;
  border-top: 2px solid #1F0970;
  border-bottom: 2px solid #1F0970;
}
table .align-right {
  text-align: right;
}
table .align-left {
  text-align: left;
}
table .align-center {
  text-align: center;
}
table tbody tr:first-child th,
table tbody tr:first-child td {
  border-top-width: 5px;
}

.table__wrapper {
  position: relative;
}
.table__wrapper table {
  width: 100%;
  position: relative;
}
.table__wrapper table a {
  display: inline-block;
  padding: 1px 5px;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 768px) {
  .table__wrapper {
    overflow: auto;
  }
  .table__wrapper table {
    min-width: 800px;
    width: initial;
  }
  .table__wrapper table th,
.table__wrapper table td {
    padding: 8px 5px;
  }
  .table__wrapper.table__wrapper--big table {
    min-width: 1300px;
    width: initial;
  }
}

.btn--table {
  background: #1F0970;
  color: #fff;
  padding: 5px 10px !important;
  margin-top: 7px;
  text-decoration: none !important;
}
@media (hover: hover) {
  .btn--table:hover {
    background: #1F0970;
    color: #fff;
  }
}

fieldset {
  border: none;
  padding: 0;
}

.form-h-info {
  margin-top: 5px;
  font-size: 0.625rem;
  max-height: 0;
  overflow: hidden;
  color: #6C6C6C;
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}

.form__select {
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;
}
.form__select label {
  color: #6C6C6C;
  font-size: 0.8125rem;
  margin-bottom: 6px;
  font-weight: 500;
  display: block;
}
.form__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1rem;
  padding: 8px 80px 8px 8px;
  border: 1px solid #E8E9E5;
  display: block;
  background: transparent;
  width: 100%;
  padding-right: 40px;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
}
.form__select select:focus, .form__select select:focus-visible {
  outline: 2px solid #3B8245;
  border-color: #3B8245;
  box-shadow: none;
  outline-offset: 0;
}
.form__select option {
  border: none;
  padding: 10px;
  font-size: 1rem;
  color: #000;
}
.form__select:after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: 18px;
  z-index: -1;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
}
@media (max-width: 576px) {
  .form__select {
    width: 100%;
  }
  .form__select select {
    width: 100%;
  }
}

.form__checkbox {
  margin-bottom: 15px;
}
.form__checkbox input {
  position: absolute;
  left: -5000px;
  opacity: 0;
}
.form__checkbox a {
  text-decoration: underline;
  color: #000;
}
@media (hover: hover) {
  .form__checkbox a:hover {
    color: #1F0970;
  }
}
.form__checkbox label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  color: #000;
  font-size: 0.875rem;
  line-height: 1.4;
  cursor: pointer;
  display: inline-block;
}
.form__checkbox label:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -2px;
  left: 0;
  display: block;
  border: 1px solid #1F0970;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 5px;
  width: 0;
  height: 0px;
  transform: rotate(-45deg);
}
.form__checkbox input:checked ~ label:before {
  background-color: #1F0970;
}
.form__checkbox input:checked ~ label:after {
  width: 10px;
  height: 6px;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
}
.form__checkbox input:focus-visible ~ label:before {
  outline: 2px solid #00af9a;
  outline-offset: 2px;
}
.form__checkbox input[type=radio] ~ label:before {
  border-radius: 50%;
}
.form__checkbox input[type=radio]:checked ~ label:after {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 50%;
  background: #1F0970;
  top: 2px;
  left: 4px;
}
.form__checkbox input[type=radio]:checked ~ label:before {
  background: #fff;
}
.form__checkbox.is--invalid label:before {
  border-color: #D93526;
}

.form__checkbox--small label {
  font-size: 0.625rem;
}

.label, .label2 {
  font-size: 0.8125rem;
  margin-bottom: 5px;
  font-weight: 700;
  color: #1F0970;
  display: block;
}

.form__input {
  margin-bottom: 15px;
  position: relative;
}
.form__input input {
  border: 1px solid #C7C1DB;
  width: 100%;
  display: block;
  color: #000;
  line-height: 1.3;
}
.form__input label, .form__input .label {
  font-size: 0.8125rem;
  margin-bottom: 5px;
  font-weight: 700;
  color: #1F0970;
  display: block;
  min-height: 22px;
}
.form__input label small, .form__input .label small {
  font-size: 80%;
  font-weight: 400;
}
.form__input input,
.form__input textarea {
  border: 1px solid #D6DEEC;
  width: 100%;
  display: block;
  color: #000;
  font-size: 16px;
  padding: 15px 35px 12px 20px;
}
.form__input input:focus, .form__input input:focus-visible,
.form__input textarea:focus,
.form__input textarea:focus-visible {
  outline: 2px solid #d6deec;
  border-color: #d6deec !important;
  box-shadow: none;
  outline-offset: 0;
}
.form__input.is--invalid input,
.form__input.is--invalid textarea {
  border-color: #D93526 !important;
}
.form__input.is--invalid input:focus, .form__input.is--invalid input:focus-visible,
.form__input.is--invalid textarea:focus,
.form__input.is--invalid textarea:focus-visible {
  outline: 2px solid #D93526;
  border-color: #D93526;
  box-shadow: none;
}
.form__input.is--invalid:after {
  content: "+";
  transform: rotate(45deg);
  color: #D93526;
  position: absolute;
  background: transparent;
  top: 32px;
  right: 10px;
  font-size: 1.3125rem;
}
.form__input.is--valid input,
.form__input.is--valid textarea {
  border-color: #3B8245 !important;
}
.form__input.is--valid input:focus, .form__input.is--valid input:focus-visible,
.form__input.is--valid textarea:focus,
.form__input.is--valid textarea:focus-visible {
  outline-color: #3B8245;
}
.form__input.is--valid:after {
  content: "";
  transform: rotate(-45deg);
  border-left: 2px solid #3B8245;
  border-bottom: 2px solid #3B8245;
  position: absolute;
  top: 42px;
  right: 15px;
  display: block;
  width: 12px;
  height: 8px;
}

.form__input--bold label {
  font-size: 1.3125rem;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}
.form__input--bold.is--valid:after {
  top: 52px;
}
.form__input--bold.is--invalid:after {
  top: 46px;
}

.form__input--error {
  color: #D93526;
  font-size: 0.625rem;
  display: block;
  margin-top: 5px;
}

.form__checkbox .form__input--error {
  margin-bottom: 5px;
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}

.form-s fieldset {
  margin-bottom: 30px;
}
.form-s fieldset {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 20px;
}
.form-s .form__input--25 {
  max-width: calc(50% - 15px);
}
.form-s .form__input,
.form-s .form__select {
  width: 100%;
}
@media (min-width: 768px) {
  .form-s .form__input,
.form-s .form__select {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .form-s .form__input,
.form-s .form__select,
.form-s .form__checkbox--50 {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }
  .form-s .form__input--25 {
    max-width: calc(25% - 15px);
  }
}

.cart__customer {
  display: flex;
  margin-bottom: 30px;
  margin-top: 40px;
  align-items: center;
  gap: 15px;
  font-size: 0.875rem;
}
.cart__customer .form__checkbox {
  margin: 0;
}
.cart__customer .form__checkbox label {
  font-size: 1rem;
  font-weight: 700;
  border: none;
  color: #000;
  min-height: auto;
}
.cart__customer .form__checkbox label:before {
  top: 50% !important;
  transform: translateY(-50%);
}
.cart__customer .form__checkbox label:after {
  top: 50% !important;
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .cart__customer {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.button__password {
  position: absolute;
  right: 2px;
  top: 26px;
  width: 40px;
  height: 38px;
  background-image: url("../img/eye.svg");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  z-index: 20;
  background-color: transparent;
}
.button__password:after {
  content: "";
  display: block;
  height: 1px;
  background: #ddd;
  transform: rotate(45deg);
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 20px;
  top: 18px;
  left: 10px;
}
.button__password.show:after {
  top: 10px;
  left: 10px;
  width: 0px;
}

.form__password.form__input:after {
  content: none !important;
  opacity: 0;
}

.btn-gnerate {
  background: #fff;
  font-size: 0.875rem;
  color: #000;
  padding: 10px 15px;
  white-space: nowrap;
  font-weight: 700;
  border: 1px solid #000 !important;
}
@media (hover: hover) {
  .btn-gnerate:hover {
    background-color: #D6DEEC;
  }
}

.generate__score {
  transition: all 0.3s ease-out;
  max-height: 0px;
  overflow: hidden;
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  line-height: 1.2;
  gap: 10px;
}
@media (min-width: 1200px) {
  .generate__score {
    gap: 20px;
  }
}
@media (max-width: 410px) {
  .generate__score {
    font-size: 0.75rem;
  }
}
.generate__score .generate__password {
  display: block;
}
.generate__score.open {
  max-height: 100px;
}

#input-accept {
  border: none;
  background: none;
  color: #1F0970;
  padding: 10px 0;
  font-size: 0.875rem;
  font-weight: 700;
}

.generate__wrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
  color: #000;
}
@media (min-width: 992px) {
  .generate__wrapper {
    margin-top: 25px;
  }
}
@media (min-width: 992px) {
  .generate__wrapper {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
    align-items: flex-start;
  }
}

@media (max-width: 390px) {
  .generate__wrapper {
    flex-direction: column;
  }

  .generate__score .generate__password {
    display: inline-block;
    margin-left: 10px;
  }
}
.fieldset-outer {
  border-bottom: 4px solid #b3e4fa;
  padding-bottom: 30px;
  margin-bottom: 30px;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}
.fieldset-outer [class*=col-] {
  margin-bottom: 0px;
}

.fieldset--start {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.fieldset1 legend {
  font-size: 30px;
  color: #1F0970;
  font-weight: 600;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .fieldset1 legend {
    font-size: 26px;
  }
}

.formRadio label {
  width: 100%;
  height: 180px;
  color: #08abed;
  font-size: 30px;
  font-weight: 600;
  font-style: italic;
  border: 1px solid #b3e4fa;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 768px) {
  .formRadio label {
    height: 120px;
    font-size: 26px;
  }
}
.formRadio input {
  opacity: 0;
  position: absolute;
  z-index: -10000;
  width: 0;
  height: 0;
  overflow: hidden;
}
.formRadio input:checked + label {
  background: #08abed;
  color: #fff;
}

.formRadio--2 label {
  border-color: #c7c1db;
  color: #4a3887;
}
.formRadio--2 input:checked + label {
  background: #4a3887;
}

.form__checkbox--2 {
  margin-bottom: 10px;
}
.form__checkbox--2 label {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px 15px;
  border: 1px solid #D6DEEC;
  color: #1F0970;
}
.form__checkbox--2 label:before {
  content: none !important;
}
.form__checkbox--2 label:after {
  content: none !important;
}
.form__checkbox--2 input:checked + label {
  background: #1F0970;
  color: #fff;
}

.group-describe {
  border: 1px solid #D6DEEC;
  padding: 25px 20px 10px 20px;
  position: relative;
}
.group-describe .label {
  margin-bottom: 20px;
  padding: 5px 10px;
  position: absolute;
  top: -13px;
  left: 10px;
  background: #fff;
}

.group-describe-outer {
  padding-top: 20px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .group-describe-md-50 {
    max-width: 50%;
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-add {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #1F0970;
  background: #fff;
  color: #1F0970;
  font-weight: 700;
  line-height: 22px;
}
.btn-add:before {
  content: "+";
  margin-right: 12px;
  font-size: 20px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #1F0970;
  color: #fff;
  border-radius: 50%;
}

.medicione-border {
  padding: 20px;
  border: 1px solid #d6deec;
  position: relative;
  margin-top: 40px;
  padding-top: 40px;
}

.register__h {
  position: absolute;
  top: -15px;
  background: #1F0970;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
}

.medicine-title {
  color: #1F0970;
  padding: 10px 20px;
  text-align: center;
  background: #d6deec;
  font-weight: 600;
}

.btn--big {
  color: #fff;
  background: #1F0970;
  padding: 20px 40px;
  font-size: 24px;
  border: none;
  font-weight: 700;
}

#objawy_niepozadane {
  min-height: 240px;
}

.removeMember {
  color: #D93526;
  line-height: 18px;
  position: absolute;
  right: 20px;
  top: 10px;
  background: transparent;
  border: none;
  display: inline-flex;
  gap: 5px;
  font-size: 12px;
  opacity: 0.3;
}
.removeMember:after {
  content: "+";
  font-size: 16px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #D93526;
  color: #fff;
  border-radius: 50%;
  transform: rotate(45deg);
}
.removeMember:hover {
  opacity: 1;
}

.medicineToCopyWrapper--1 .medicine-title {
  background: #b3e4fa;
}
.medicineToCopyWrapper--1 .register__h {
  background: #b3e4fa;
  color: #1F0970;
}
.medicineToCopyWrapper--1 .medicione-border {
  border-color: #b3e4fa;
}
.medicineToCopyWrapper--1 .btn-add {
  background: #b3e4fa;
  border-color: #b3e4fa;
}

.medicineToCopyWrapper2 .medicine-title {
  background: #c7c1db;
}
.medicineToCopyWrapper2 .register__h {
  background: #c7c1db;
  color: #1F0970;
}
.medicineToCopyWrapper2 .medicione-border {
  border-color: #c7c1db;
}
.medicineToCopyWrapper2 .btn-add {
  background: #c7c1db;
  border-color: #c7c1db;
}

.error {
  color: #D93526;
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
  display: none;
}
.error:before {
  content: "! ";
}

.pacjent .fieldset-outer {
  border-bottom-color: #4a3887;
}
.pacjent .btn--big {
  background: #4a3887;
}
.pacjent .legend1 {
  color: #4a3887;
}
.pacjent .group-adress .error {
  display: none !important;
  max-height: 0 !important;
  overflow: hidden;
}
.pacjent .group-adress .star {
  display: none;
}

.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;
}
.img__wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img__wrapper img {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.of-contain {
  object-fit: contain !important;
}

.bg--main2 {
  background: #1F0970 !important;
}

.bg--main {
  background: #1F0970 !important;
}

.bg--grey {
  background: #eee !important;
}

.bg--light {
  background: #D6DEEC !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -100;
  opacity: 0;
}

.alert,
#komunikat,
.komunikat {
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  background-color: #f8fbcb;
  color: #000 !important;
  display: block;
}

.alert-success {
  background-color: #ebf3e8 !important;
  color: #007A3B !important;
}

.alert-info {
  background-color: #eaf6fb !important;
}

.alert-warning {
  background-color: #f8f8cb !important;
}

.alert-danger {
  background-color: #faeded !important;
  color: #ca0038 !important;
}

.alert-grey {
  background-color: #eee !important;
}

.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.footer {
  padding-top: 130px;
  position: relative;
  background: #D6DEEC;
}
.footer .footer__bg-img {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.f__contact {
  display: block;
  padding: 10px 0;
  margin-bottom: 5px;
  font-size: 1.125rem;
  color: #1F0970 !important;
  background-position: 0 center;
  background-repeat: no-repeat;
  padding-left: 30px;
}
@media (hover: hover) {
  .f__contact:hover {
    color: #08ABED !important;
  }
}

.f__contact--fb {
  background-image: url("../img/ico-fb2.svg");
}

.f__contact--mail {
  background-image: url("../img/ico-mail2.svg");
}

.f__contact--tel {
  background-image: url("../img/ico-phone.svg");
}

.footer__bottom {
  font-size: 11px;
}
.footer__bottom img {
  max-height: 40px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.footer__wrapper {
  position: relative;
  z-index: 5;
  display: grid;
  gap: 100px 30px;
  grid-template-columns: 0.7fr 1fr 1fr 1fr;
}

.footer__item li a {
  display: block;
  width: 100%;
  padding: 3px 0;
}

.footer__list2 a {
  font-size: 1.125rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.social__list {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social__link {
  min-width: 48px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__bottom {
  padding-top: 70px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  align-items: center;
  position: relative;
  z-index: 10;
}

.footer__bottomList {
  display: flex;
  gap: 50px;
}
.footer__bottomList a {
  display: block;
  padding: 15px 0;
}

@media (max-width: 992px) {
  .footer__item .btn {
    min-width: auto;
    padding-right: 15px;
    padding-left: 15px;
  }

  .footer__wrapper {
    grid-template-columns: 1fr 0.8fr 0.8fr;
    gap: 50px 30px;
  }

  .footer__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 30px;
  }
}
@media (max-width: 768px) {
  .footer__wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 35px 20px;
  }
}
@media (min-width: 576px) {
  .footer__item .accordion__content {
    display: block !important;
    transition: none !important;
  }
  .footer__item .accordion__trigger {
    padding: 0;
    margin-bottom: 20px;
  }
  .footer__item .accordion__trigger:after {
    content: none;
  }
}
@media (max-width: 576px) {
  .footer {
    padding-top: 30px;
  }

  .footer__wrapper {
    grid-template-columns: 1fr;
    gap: 5px 20px;
  }

  .footer__item ul {
    padding-bottom: 30px;
  }

  .social__list {
    justify-content: center;
  }

  .footer__item--4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 10px;
}
.breadcrumbs ul {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumbs ul span,
.breadcrumbs ul a {
  font-size: 14px;
  color: #000;
  display: inline-block;
  padding: 5px 0;
  line-height: 1;
  font-weight: 300;
}
.breadcrumbs ul span:before,
.breadcrumbs ul a:before {
  margin-right: 10px;
  color: #000;
  content: "/";
}
.breadcrumbs ul li:first-child a:before {
  content: none;
}
.breadcrumbs ul a {
  margin-right: 10px;
}
.breadcrumbs ul a svg {
  stroke: #000;
  transition: all 0.3s ease-in;
}
.breadcrumbs ul a:focus, .breadcrumbs ul a:hover {
  outline: none;
  color: #1F0970;
}
.breadcrumbs ul a:focus svg, .breadcrumbs ul a:hover svg {
  stroke: #1F0970;
}
.breadcrumbs ul a:focus:after, .breadcrumbs ul a:hover:after {
  color: #000;
}
@media (min-width: 768px) {
  .breadcrumbs ul a,
.breadcrumbs ul span {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .page__header .breadcrumbs ul a,
.page__header .breadcrumbs ul span {
    font-size: 18px;
  }
}
@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
.header {
  position: relative;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
}
.header.fixed-header-scroll {
  position: fixed;
  top: 0;
  background: #1F0970;
  -webkit-animation-name: fixedMenuDown;
  animation-name: fixedMenuDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
}
.header.fixed-header-scroll .header__top {
  display: none;
}
.header.fixed-header-scroll .header__logo img {
  filter: brightness(0) invert(1);
}
.header.fixed-header-scroll .header__wrapper {
  height: 60px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.header.fixed-header-scroll .header__bottom:after {
  content: none;
}
.header.fixed-header-scroll .nav > ul > li > a {
  color: #fff;
}
.header.fixed-header-scroll .header__logo {
  bottom: 10px;
}
.header.fixed-header-scroll .header__logo img {
  width: 100px;
}

.header__top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.lang__list {
  display: flex;
  margin-right: 70px;
}
.lang__list a {
  display: block;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  color: #fff;
}
.lang__list a.active {
  font-weight: 700;
}
@media (hover: hover) {
  .lang__list a:hover {
    color: #08ABED;
  }
}
.lang__list li:not(:last-child) {
  border-right: 1px solid #fff;
}

.header__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 220px;
  position: relative;
  height: 170px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header__logo {
  position: absolute;
  bottom: 0%;
  left: 20px;
  z-index: 10;
  transition: none;
}
.header__logo img {
  width: 240px;
}

.headerForm {
  width: 48px;
  height: 48px;
  position: relative;
  margin-left: 40px;
  margin-right: auto;
}
.headerForm input {
  border: none;
  width: 100%;
  padding-right: 40px;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #fff;
  border-radius: 25px;
  font-weight: 600;
  min-height: 50px;
  border: 2px solid #C7C1DB;
}
.headerForm ::placeholder {
  color: #000;
  font-weight: 600;
}
.headerForm button {
  position: absolute;
  width: 48px;
  right: 0px;
  bottom: 0px;
  border: none;
  padding: 0;
  display: inline-block;
  background: transparent;
  height: 48px;
  background-image: url("../img/ico-search-main.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.header__accesibility {
  border: 1px solid #C7C1DB;
  border-radius: 25px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-right: 30px;
  overflow: hidden;
  margin-left: auto;
}
.header__accesibility li {
  height: 48px;
}
.header__accesibility a,
.header__accesibility button {
  display: inline-block;
  height: 100%;
  min-width: 48px;
  height: 48px;
  padding: 0;
  background-color: transparent;
}

.link--add {
  background-image: url("../img/ico-add.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.btn--contrast {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/ico-contrast.svg");
  border: none;
  vertical-align: middle;
}
.btn--contrast span {
  min-width: 210px;
}

.nav-toggle {
  width: 50px;
  padding: 0;
  padding-left: 50px;
  height: 50px;
  background: none;
  border: none;
  display: none;
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: 1.3125rem;
  left: -10px;
  line-height: 50px;
}
.nav-toggle strong {
  margin-left: -20px;
}
.nav-toggle span {
  width: 26px;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  left: 12px;
  top: 15px;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}
.nav-toggle span:nth-child(2) {
  top: 25px;
}
.nav-toggle span:nth-child(3) {
  top: 35px;
}
.nav-toggle.open span:first-child {
  transform: rotate(45deg);
  top: 24px;
}
.nav-toggle.open span:nth-child(2) {
  left: 50px;
  opacity: 0;
}
.nav-toggle.open span:nth-child(3) {
  top: 24px;
  transform: rotate(-45deg);
}

.header__bottom {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}

.nav > ul {
  display: flex;
  margin-left: -15px;
}
.nav > ul > li.active > a {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 4px;
  text-decoration-color: #1F0970;
}
.nav > ul > li > a {
  font-weight: 700;
  font-size: 1.125rem;
  color: #fff;
  display: inline-block;
  padding: 10px 15px 10px 20px;
}
@media (hover: hover) {
  .nav > ul > li > a:hover {
    color: #08ABED !important;
  }
}
.nav > ul a {
  transition-property: color;
}
.header__social {
  display: flex;
  align-items: center;
  margin-top: -5px;
}
.header__social li {
  height: 48px;
}

.link--social {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-color: trnasparent;
}

.link--fb {
  background-image: url("../img/fb.svg");
}

.link--insta {
  background-image: url("../img/insta.svg");
}

.page__header {
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  overflow: hidden;
  background: #1F0970;
}
@media (max-width: 576px) {
  .page__header {
    min-height: 200px;
  }
}
.page__header img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.15;
}
.page__header .breadcrumbs {
  border: none;
}
.page__header .container {
  position: relative;
  z-index: 1;
  text-align: center;
}
.page__header h1,
.page__header h2 {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 10px;
  color: #fff;
}
@media (max-width: 576px) {
  .page__header h1,
.page__header h2 {
    font-size: 2.125rem;
  }
}
.page__header .breadcrumbs__list {
  justify-content: center;
}
.page__header .breadcrumb__items span {
  color: #fff;
}
.page__header .breadcrumb__items span:before {
  color: #fff;
}
.page__header .breadcrumb__items a {
  color: #fff;
}
.page__header .breadcrumb__items a:before {
  color: #fff;
}
@media (hover: hover) {
  .page__header .breadcrumb__items a:hover {
    color: #FDF353;
  }
}

header > ul > li {
  position: relative;
}

.menu__item--has-children ul {
  display: none;
}

@media (min-width: 576px) {
  .header__wrapper:after {
    background: #1F0970;
    content: "";
    height: 90px;
    width: 100%;
    left: 220px;
    bottom: 0;
    z-index: -2;
    position: absolute;
  }
}
@media (min-width: 992px) {
  .header__wrapper:after {
    left: 340px;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .header__bottom {
    margin-right: 100px;
  }
}
@media (min-width: 1620px) and (max-width: 1800px) {
  .header__wrapper {
    padding-right: 150px;
  }
}
@media (min-width: 1350px) and (max-width: 1620px) {
  .header__wrapper {
    padding-right: 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1350px) {
  .header__wrapper {
    padding-right: 120px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .header__wrapper {
    padding-right: 80px !important;
  }
}
@media (min-width: 1200px) {
  .nav > ul > li > a {
    color: #fff;
  }

  .menu__item--has-children {
    position: relative;
  }
  .menu__item--has-children ul {
    padding-top: 16px;
    position: absolute;
    display: block;
    left: -10px;
    height: 0;
    overflow: hidden;
    min-width: 250px;
    z-index: -130;
    top: 100%;
    transition: opacity 0.5s ease-in-out, left 0.3s ease-in-out;
    opacity: 0;
  }
  .menu__item--has-children ul li {
    background: #1F0970;
    border-top: none;
  }
  .menu__item--has-children ul li:first-child a {
    padding-top: 20px;
  }
  .menu__item--has-children ul li:last-child a {
    padding-bottom: 20px;
  }
  .menu__item--has-children ul a {
    line-height: 1.2;
    font-size: 1.125rem;
    font-weight: 700;
    display: block;
    padding: 10px 15px;
    color: #1F0970;
  }
}
@media (min-width: 1200px) and (hover: hover) {
  .menu__item--has-children ul a:hover {
    background: #08ABED;
  }
}
@media (min-width: 1200px) {
  .menu__item--has-children ul a:focus-visible {
    outline-color: #1F0970;
    outline-offset: -2px;
  }
}
@media (min-width: 1200px) {
  .menu__item--has-children.open ul {
    z-index: 30;
    height: initial;
    opacity: 1;
    left: 0;
  }
}
@media (min-width: 1200px) {
  .menu__item--has-children:hover ul {
    z-index: 30;
    height: initial;
    opacity: 1;
    left: 0;
  }
}
@media (max-width: 1200px) {
  .menu-open .header {
    background: #1F0970;
    transition: none;
  }

  .header {
    transition: background 0s ease-out 0.3s;
  }

  .btn__login {
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    padding: 0;
    text-indent: -1000px;
    overflow: hidden;
    height: 48px;
    background: transparent url("../img/user.svg") center no-repeat;
    background-size: 15px;
  }

  .headerForm button {
    background-image: url("../img/ico-search.svg");
  }

  .menu__item--has-children {
    position: relative;
  }
  .menu__item--has-children > a {
    width: 70% !important;
  }
  .menu__item--has-children:after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: absolute;
    right: 35px;
    top: 20px;
    transform: rotate(-45deg);
  }

  .navMain__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 20px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px solid #000;
  }

  .header__wrapper {
    flex-wrap: nowrap;
    height: 70px;
    padding-left: 20px;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    width: 100%;
    padding-right: 5px;
  }

  .headerForm {
    width: 100%;
  }

  .navMain {
    position: fixed;
    z-index: 100;
    height: calc(100vh - 60px);
    bottom: 60px;
    width: 80vw;
    left: -80vw;
    transition: left 0.3s ease-out;
    box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
  }
  .navMain.open {
    left: 0;
  }
  .navMain ul {
    display: block;
  }
  .navMain ul li {
    width: 100%;
  }
  .navMain ul li a {
    width: 100%;
    font-size: 16px;
    padding: 10px 0;
    display: inline-block;
    color: #000;
  }
  .navMain ul ul {
    width: 100%;
    position: relative;
    display: none;
    overflow: auto;
    padding: 0;
    transition: all 0.3s ease-out;
  }
  .navMain ul ul.open {
    max-height: 500px;
  }
  .navMain ul ul a {
    padding-left: 20px;
  }
  .navMain ul ul ul a {
    padding: 7px 0 7px 30px;
    font-size: 14px;
  }
  .navMain > ul > li {
    border-bottom: 1px solid #C7C1DB;
  }
  .navMain > ul > li > a,
.navMain > ul > li > ul {
    padding-left: 20px;
    padding-left: 20px;
    border: none;
  }
}
@media (max-width: 992px) {
  .header__wrapper.container {
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .lang__list {
    margin-right: 5px;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    text-align: center;
  }
  .lang__list li {
    border: none !important;
  }
  .lang__list li a {
    font-size: 10px;
  }
}
.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}

.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}

.menu-open .menu-right__wrapper {
  z-index: 0;
}

.nav-subpage {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(0, 110, 219, 0.11);
}
.nav-subpage ul {
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
}
.nav-subpage a {
  color: #1F0970;
  position: relative;
  padding: 5px 0;
  font-size: 1.125rem;
  font-weight: 700;
  display: block;
}
.nav-subpage a:before {
  content: "•  ";
}
.nav-subpage a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  height: 4px;
  left: 0;
  width: 0;
  transition: all 0.3s ease-in-out;
  background: #1F0970;
}
.nav-subpage a.active {
  color: #1F0970;
}
.nav-subpage a.active:after {
  background: #1F0970;
  width: 100%;
}
@media (hover: hover) {
  .nav-subpage a:hover {
    color: #1F0970;
  }
  .nav-subpage a:hover:after {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .nav .nav__wrapper {
    position: absolute;
    position: absolute;
    top: 100%;
    flex-direction: column;
    background: #1F0970;
    width: 100%;
    left: 20px;
    padding: 0 0px 0 10px;
    max-height: 0;
    overflow: hidden;
    margin-top: 0;
    transition: all 0.3s ease-out;
  }
  .nav .nav__wrapper.open {
    max-height: calc(100vh - 190px);
    overflow: auto;
  }
  .nav .nav__wrapper > li:first-child {
    margin-top: 15px;
  }
  .nav .nav__wrapper > li:last-child {
    margin-bottom: 25px;
  }

  .nav {
    position: relative;
    width: 100%;
  }
  .nav > ul > li > a {
    padding-top: 10px;
    width: 100%;
    text-align: center;
  }

  .nav-toggle {
    display: block;
  }

  .header__social {
    position: absolute;
    bottom: 0px;
    right: 20px;
  }

  .header__bottom {
    position: relative;
  }

  .menu__item--has-children ul {
    padding-left: 20px;
    display: block;
  }
  .menu__item--has-children ul a {
    color: #fff;
    display: block;
    padding: 10px;
  }
}
@media (max-width: 1200px) {
  .header__top.container, .header__bottom.container {
    max-width: 100%;
  }

  .nav__wrapper.nav__level-1 ul {
    display: none;
  }

  .nav__wrapper.nav__level-1 ul.open {
    max-height: 600px;
    overflow: auto;
  }

  .nav .nav__wrapper {
    left: -20px;
    width: 100vw;
    position: fixed;
    top: 70px;
    left: 0;
    margin-left: 0;
  }

  .fixed-header-scroll .nav .nav__wrapper {
    top: 60px;
  }

  .nav > ul > li > a {
    font-size: 1.625rem;
  }

  .menu__item--has-children ul a {
    font-size: 1.625rem;
  }

  .header__bottom {
    margin-top: 0;
    width: 110px;
    flex: 0 0 110px;
    height: 48px;
    justify-content: flex-end;
  }

  .header__top {
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    margin-left: auto;
  }
  .header__top .btn--help {
    position: absolute;
    top: 68px;
    right: 20px;
    padding-right: 15px !important;
    padding-left: 40px;
    padding-top: 0;
    padding-bottom: 0;
    height: 48px;
    font-size: 16px;
    line-height: 1;
    z-index: 80;
    line-height: 48px;
  }
  .header__top .btn--help:after {
    left: 15px;
    margin-top: 3px;
    text-indent: 0;
  }

  .link--social {
    display: none;
  }

  .header__social {
    margin-right: 150px;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    margin-top: 0;
    bottom: -4px;
  }
  .header__social li {
    height: 60px;
  }

  .header__logo {
    max-width: calc(100% - 220px);
    height: auto;
    position: static;
    transform: none;
    line-height: 1;
    flex: 0 0 160px;
  }
  .header__logo img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .header__accesibility {
    margin-right: 0;
    gap: 2px;
  }

  .nav {
    margin-left: auto;
    flex: 0 0 50px;
    max-width: 50px;
  }

  .headerForm {
    position: absolute;
    bottom: 0px;
    height: 50px;
    width: 50px;
    left: 0px;
    margin: 0;
  }
  .headerForm input {
    padding-top: 2px;
    padding-bottom: 2px;
    min-height: auto;
  }
}
@media (max-width: 576px) {
  .nav .nav__wrapper.open {
    max-height: calc(100vh - 70px);
    height: 100vh;
    overflow: auto;
  }

  .nav > ul > li > a {
    text-align: left;
  }

  .header {
    background: #1F0970;
  }
  .header .header__logo img {
    filter: brightness(0) invert(1);
  }
}
.menu-right__wrapper {
  position: absolute;
  padding-top: 49%;
  right: 0;
  z-index: 200;
  width: 12%;
  overflow: hidden;
  top: 0;
  min-height: 500px;
  max-height: 75vh;
}
@media (min-width: 768px) {
  .menu-right__wrapper figure {
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    overflow: hidden;
  }
}
.menu-right__wrapper .menu-right__bg {
  height: 100%;
  width: auto;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.menu-right__wrapper .menu-right {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
}

@media (min-width: 992px) {
  .mainpage .menu-right__wrapper {
    min-height: 560px;
  }
}
@media (min-width: 992px) {
  .mainpage .menu-right__wrapper {
    min-height: 660px;
  }
}
@media (min-width: 1620px) {
  .mainpage .menu-right__wrapper figure {
    min-height: 800px;
  }
}

.menu-right__btn {
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
  display: inline-block;
  background: #1F0970 url("../img/ico-mail.svg") no-repeat center;
  background-size: 60%;
}

.menu-right__btn--share {
  background-color: #B3E4FA;
  background-image: url("../img/ico-share.svg");
}

@media (max-width: 1620px) {
  .menu-right__btn {
    width: 48px;
    height: 48px;
    margin-bottom: 5px;
  }

  .logo-ue img {
    width: 48px;
    height: auto;
  }
}
@media (max-width: 576px) {
  .menu-right__wrapper {
    top: 70px;
    padding-top: 55%;
  }
  .menu-right__wrapper .menu-right__bg {
    display: none;
  }
}
.accordion__trigger {
  padding: 20px 40px 20px 0;
  font-weight: 700;
  border: none;
  background: transparent;
  font-size: 18px;
  width: 100%;
  text-align: left;
  color: #000;
  position: relative;
}
.accordion__trigger:after {
  content: "";
  position: absolute;
  top: 22px;
  right: 10px;
  display: block;
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  transition: all 0.3s ease-out;
  transform-origin: center;
}
.accordion__trigger.active:after, .accordion__trigger[aria-expanded=true]:after {
  top: 26px;
  transform: rotate(135deg);
}

.accordion__content {
  padding: 0;
  background: transparent;
  font-size: 18px;
  width: 100%;
  display: none;
}
.accordion__content p,
.accordion__content ol,
.accordion__content ul {
  margin-bottom: 15px;
}

.accordion__panel {
  padding: 0 0 10px 0;
}

.accordion {
  border-bottom: 1px solid #dedede;
}

.tabs__navlist {
  display: inline-flex;
  gap: 10px;
  position: relative;
  overflow: auto;
  max-width: 100%;
  margin-bottom: 20px;
}
.tabs__navlist:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #C7C1DB;
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: -1;
  display: block;
}
.tabs__navlist .tabs__nav-trigger {
  padding: 10px 0 8px 0;
  border: none;
  background: none;
  display: inline-bock;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}
@media (hover: hover) {
  .tabs__navlist .tabs__nav-trigger:hover {
    color: #1F0970;
  }
}
.tabs__navlist .tabs__nav-trigger[aria-selected=true] {
  border-color: #000;
  color: #000;
}
@media (min-width: 1200px) {
  .tabs__navlist {
    gap: 20px;
  }
}

.tabs__panel {
  display: none;
}
.tabs__panel.is-current {
  display: block;
}
@media (min-width: 768px) {
  .tabs__panel {
    margin-bottom: 30px;
  }
}

.go-top {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 48px;
  display: inline-block;
  height: 48px;
  background: url("../img/ico_top.svg");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

#scrollDown {
  position: absolute;
  bottom: 80px;
  right: calc(50vw + 700px);
  z-index: 20;
  color: #1F0970;
  text-transform: uppercase;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  font-size: 10px;
  font-weight: 700;
  transform: rotate(-90deg);
  background: none;
  border: none;
  transition: none;
}
#scrollDown:before {
  content: url("../img/arrow-right-blue.svg");
  transform: rotate(-180deg);
  width: 10px;
}
@media (max-width: 1620px) {
  #scrollDown {
    right: calc(50vw + 520px);
  }
}
@media (max-width: 1200px) {
  #scrollDown {
    right: calc(50vw + 400px);
  }
}
@media (max-width: 992px) {
  #scrollDown {
    right: initial;
    left: 10px;
  }
}

.video__wrapper {
  height: 0;
  padding-top: 56%;
  position: relative;
  margin-top: -30px;
  margin-bottom: 100px;
}
.video__wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1;
  width: 100%;
}

.video__img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  width: 60px;
  height: 60px;
  background-image: url("../img/play.svg");
  background-size: contain;
  background-position: center;
  background-color: transparent;
  border: none;
}

.video__title {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
  z-index: 5;
  font-weight: 400;
  width: 100%;
}

@media (max-width: 768px) {
  .video__wrapper {
    margin-bottom: 50px;
  }

  .video__title {
    top: 70%;
    font-size: 16px;
  }
}