.accordion__trigger {
  padding: 20px 40px 20px 0;
  font-weight: 700;
  border: none;
  background: transparent;
  font-size: 18px;
  width: 100%;
  text-align: left;
  color: #000;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 22px;
    right: 10px;
    display: block;
    width: 12px;
    height: 12px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
    transform-origin: center;
  }
  &.active, &[aria-expanded='true'] {
    &:after {
      top: 26px;
      transform: rotate(135deg);
    }
  }
}

.accordion__content {
  padding: 0;
  background: transparent;
  font-size: 18px;
  width: 100%;
  display:none;
  p,
  ol,
  ul {
    margin-bottom: 15px;
  }
}

.accordion__panel {
  padding: 0 0 10px 0;
}

.accordion {
  border-bottom: 1px solid #dedede;
}