.tabs__navlist {
  display: inline-flex;
  gap: 10px;
  position: relative;
  overflow: auto;
  max-width: 100%;
  margin-bottom:20px;


  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background: $line;
    position: absolute;
    bottom: 1px;
    left: 0;
    z-index: -1;
    display: block;
  }

  .tabs__nav-trigger {
    padding: 10px 0 8px 0;
    border: none;
    background: none;
    display: inline-bock;
    border-bottom: 3px solid transparent;
    text-transform: uppercase;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }

    &[aria-selected="true"] {
      border-color: $txt;
      color: $txt;
    }
  }

  @media(min-width: $grid-breakpoints-xl) {
    gap: 20px;
  }
}


.tabs__panel {
  display: none;

  &.is-current {
    display: block;
  }

  @media(min-width: $grid-breakpoints-md) {
    margin-bottom: 30px;;
  }
}
