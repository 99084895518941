/* lato-300 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/lato-v24-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-v24-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/lato-v24-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/lato-v24-latin_latin-ext-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/lato-v24-latin_latin-ext-300.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/lato-v24-latin_latin-ext-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-300italic - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/lato-v24-latin_latin-ext-300italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-v24-latin_latin-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/lato-v24-latin_latin-ext-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/lato-v24-latin_latin-ext-300italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/lato-v24-latin_latin-ext-300italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/lato-v24-latin_latin-ext-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lato-v24-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-v24-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/lato-v24-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/lato-v24-latin_latin-ext-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/lato-v24-latin_latin-ext-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/lato-v24-latin_latin-ext-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-italic - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/lato-v24-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-v24-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/lato-v24-latin_latin-ext-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/lato-v24-latin_latin-ext-italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/lato-v24-latin_latin-ext-italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/lato-v24-latin_latin-ext-italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/lato-v24-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-v24-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/lato-v24-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/lato-v24-latin_latin-ext-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/lato-v24-latin_latin-ext-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/lato-v24-latin_latin-ext-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700italic - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/lato-v24-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-v24-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/lato-v24-latin_latin-ext-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/lato-v24-latin_latin-ext-700italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/lato-v24-latin_latin-ext-700italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/lato-v24-latin_latin-ext-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/lato-v24-latin_latin-ext-900.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-v24-latin_latin-ext-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/lato-v24-latin_latin-ext-900.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/lato-v24-latin_latin-ext-900.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/lato-v24-latin_latin-ext-900.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/lato-v24-latin_latin-ext-900.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900italic - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/lato-v24-latin_latin-ext-900italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-v24-latin_latin-ext-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/lato-v24-latin_latin-ext-900italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/lato-v24-latin_latin-ext-900italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/lato-v24-latin_latin-ext-900italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/lato-v24-latin_latin-ext-900italic.svg#Lato') format('svg'); /* Legacy iOS */
}