@import "components/variables";
@import "components/reset";
@import "components/grid.scss";
@import "components/typography.scss";
@import "components/form.scss";
@import "components/importantClass.scss";
@import "components/footer.scss";
@import "components/header.scss";
@import "components/accordion.scss";
@import "components/tab.scss";


.go-top {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 48px;
  display: inline-block;
  height: 48px;
  background: url("../img/ico_top.svg");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}


#scrollDown {
  position: absolute;
  bottom: 80px;
  right: calc(50vw + 700px);
  z-index: 20;
  color: $main;
  text-transform: uppercase;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  font-size: 10px;
  font-weight: 700;
  transform: rotate(-90deg);
  background: none;
  border: none;
  transition: none;

  &:before {
    content: url("../img/arrow-right-blue.svg");
    transform: rotate(-180deg);
    width: 10px;
  }

  @media(max-width: $grid-breakpoints-xxl) {
    right: calc(50vw + 520px);
  }
  @media(max-width: $grid-breakpoints-xl) {
    right: calc(50vw + 400px);
  }

  @media(max-width: $grid-breakpoints-lg) {
    right: initial;
    left: 10px;
  }


}


.video__wrapper {
  height: 0;
  padding-top: 56%;
  position: relative;
  margin-top: -30px;
  margin-bottom: 100px;

  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 1;
    width: 100%;
  }
}

.video__img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
}


.video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  width: 60px;
  height: 60px;
  background-image: url("../img/play.svg");
  background-size: contain;
  background-position: center;
  background-color: transparent;
  border: none;

}

.video__title {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
  z-index: 5;
  font-weight: 400;
  width: 100%;
}

@media(max-width: $grid-breakpoints-md) {

  .video__wrapper {
    margin-bottom: 50px;
  }
  .video__title {
    top: 70%;
    font-size: 16px;
  }

}

