.footer {

  padding-top: 130px;
  position: relative;
  background: #D6DEEC;

  .footer__bg-img {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: contain;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

}


.f__contact {
  display: block;
  padding: 10px 0;
  margin-bottom: 5px;
  font-size: $font18;
  color: $main !important;
  background-position: 0 center;
  background-repeat: no-repeat;
  padding-left: 30px;

  @media(hover: hover) {
    &:hover {
      color: $main-hover !important;
    }
  }
}

.f__contact--fb {
  background-image: url("../img/ico-fb2.svg");
}

.f__contact--mail {
  background-image: url("../img/ico-mail2.svg");
}

.f__contact--tel {
  background-image: url("../img/ico-phone.svg");
}


.footer__bottom {

  font-size: 11px;
  // border-top: 2px solid $line;

  img {
    max-height: 40px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }
}

.footer__wrapper {
  position: relative;
  z-index: 5;
  display: grid;
  gap: 100px 30px;
  grid-template-columns: 0.7fr 1fr 1fr 1fr;


}

.footer__item li a {
  display: block;
  width: 100%;
  padding: 3px 0;
}

.footer__list2 a {
  font-size: $font18;
  margin-bottom: 10px;
  font-weight: 700;
}

.social__list {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social__link {
  min-width: 48px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.footer__bottom {
  padding-top: 70px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  font-size: $font14;
  align-items: center;
  position:relative;
  z-index: 10;

}

.footer__bottomList {
  display: flex;

  gap: 50px;

  a {
    display: block;
    padding: 15px 0;
  }
}


@media(max-width: $grid-breakpoints-lg) {
  .footer__item .btn {
    min-width: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .footer__wrapper {
    grid-template-columns: 1fr 0.8fr 0.8fr;
    gap: 50px 30px;
  }


  .footer__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 30px;

  }
}

@media(max-width: $grid-breakpoints-md) {

  .footer__wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 35px 20px;
  }
}

@media(min-width: $grid-breakpoints-sm) {
  .footer__item {

    .accordion__content{
      display:block !important;
      transition: none !important;
    }
    .accordion__trigger {
      padding: 0;

      margin-bottom: 20px;

      &:after {
        content: none;
      }
    }
  }
}

@media(max-width: $grid-breakpoints-sm) {

  .footer {
    padding-top: 30px;
  }

  .footer__wrapper {
    grid-template-columns: 1fr;
    gap: 5px 20px;
  }

  .footer__item ul {
    padding-bottom: 30px;
  }

  .social__list {
    justify-content: center;
  }

  .footer__item--4{
    margin-top:20px;
    margin-bottom:10px;
  }
}
